<template>
  <div>
    <div id="app">
      <div class="dropdown">
        <button @click="toggleDropdown" class="dropdown-toggle">
          {{ selectedValue }}
        </button>
        <ul v-if="showDropdown" class="dropdown-menu">
          <li
            v-for="(item, index) in options"
            :key="index"
            @click="selectOption(item)"
            class="dropdown-item"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="tablbox">
        <div class="tab-header">
          <div class="tab-h"></div>
          <div class="tab-h">应售</div>
          <div class="tab-h">销售</div>
        </div>
        <div class="tab-headers">
          <div class="tab-h">育成公</div>
          <div class="tab-h">6</div>
          <div class="tab-h">3</div>
        </div>
        <div class="tab-headers">
          <div class="tab-h">育成母</div>
          <div class="tab-h">6</div>
          <div class="tab-h">3</div>
        </div>
        <div class="tab-headers">
          <div class="tab-h">育成母</div>
          <div class="tab-h">6</div>
          <div class="tab-h">3</div>
        </div>
      </div>
      <div class="target-block" v-show="count > 0">
        {{ count }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      selectedValue: "请选择",
      showDropdown: false,
      options: ["选项1", "选项2", "选项3"],
      count: 0,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.selectedValue = option;
      this.showDropdown = false;
    },
    handleScroll() {
      // 检查用户是否滚动到了目标板块
      // 如果是，则调用递增数字的方法
    },
    incrementCount() {
      if (this.count < 10) {
        this.count++;
      }
    },
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMounte() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUpdate() {},
  updated() {},
  beforeDistroy() {},
  distroyed() {},
};
</script>
<style scoped>
.target-block {
  opacity: 0;
  transition: opacity 1s;
}

.target-block.show {
  opacity: 1;
}
.tablbox {
  width: 180px;
  height: 200px;

  margin: 0 auto;
}
.tab-header {
  width: 100%;
  height: 40px;

  display: flex;
  /* border: 1px solid gray; */
  /* border-left:none;
  border-right:none;
  border-bottom: none; */
}
.tab-headers {
  width: 100%;
  height: 40px;

  display: flex;
}
.tab-h {
  width: 33.33%;
  height: 100%;
  /* border: 1px solid gray; */
  text-align: center;
  line-height: 32px;
  /* border-top: none; */
}
/* .tab-hs{
  width: 33.33%;
  height: 100%;
  border: 1px solid gray;
  text-align: center;
  line-height: 32px;
   border-left:none;
  border-right:none;
  border-top: none;
 
 
} */
</style>
